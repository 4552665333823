import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "fr",
    fallbackLng: "fr",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        fr: {
            translation: {
                name: 'Festival Truck \'N\' Roll En Coeur',
                home: {
                    label: 'Accueil',
                    description: {
                        title: 'Qu\'est-ce que le Truck \'N\' Roll?',
                        text: 'Le Festival Truck’n’Roll En Coeur n’est pas seulement un rassemblement de camions, ' +
                            'ni un festival de musique! C’est une belle occasion de s’amuser en famille et d’en ' +
                            'apprendre davantage sur le métier de camionneur. Tous les fonds amassés lors de cette' +
                            ' fin de semaine iront pour la Fondation En Coeur, qui vient en aide aux enfants qui ont' +
                            ' une maladie cardiaque et à leur famille.',
                        link: 'Apprenez-en plus sur la Fondation En Coeur en visitant: ',
                    },
                    starKids: {
                        title: 'Enfants vedettes',
                        kid1: {
                            par1: "Je vous présente mon fils Brandon Boulet, qui est né avec une malformation cardiaque " +
                                "congénitale, diagnostiqué à 32 sem 6/7 exactement. Les médecins avaient découvert que " +
                                "Brandon avait le cœur plus petit dans sa cavité droite et que la valve de son artère " +
                                "pulmonaire était soudée. Le verdict; Brandon avait une hypoplasie du cœur droit et une " +
                                "atrésie pulmonaire.  Les pronostics étaient assez bons, mis à part que Brandon devait " +
                                "être prit en charge immédiatement à la naissance car il ne pouvait s’alimenter en " +
                                "oxygène avec sa valve obstruée, ainsi que la partie droite du cœur sous-développée, " +
                                "qui rapatrie le sang pauvre en oxygène pour l’envoyer aux poumons afin de l’oxygéner " +
                                "à nouveau.",
                            par2: 'Brandon a donc subi 2 interventions chirurgicales par l’aîne, à 5 et 6 jours de vie. ' +
                                'L’opération avait pour but de poser un *stent* dans son canal artériel (le canal qui ' +
                                'nourrit le fœtus via le cordon ombilical in utero) Ce canal se referme de 24 à 48 ' +
                                'heures après la naissance pour que le cœur prenne 100% de ses fonctions lui-même, mais' +
                                ' le petit cœur de Brandon devait continuer de fonctionner comme in utéro car il n’y ' +
                                'avait pas de passage possible du sang de l’artère pulmonaire vers les poumons, la ' +
                                'raison pour laquelle la mise en place du stent était vital pour lui. L’opération ne ' +
                                'fonctionna pas à 5 jours et le petit corps de Brandon devenait trop bleu pour continuer…. ' +
                                'Ils ont donc repris l’opération le lendemain qui fut un succès. La pose du stent était ' +
                                'temporaire, le temps que son cœur grandisse un peu avant de devoir subir une ' +
                                'intervention à cœur ouvert.',
                            par3: 'Brandon a pu revenir à la maison après 2 semaines d’hospitalisation. Sa santé était ' +
                                'quand même bonne malgré le fait que sa saturation (taux d’oxygène dans le sang) ' +
                                'n’était pas celle d’un enfant normal, elle variait entre 75 et 85% alors qu’une ' +
                                'personne normale se situe entre 95 à 100%. Nous devions donc surveiller ses signes ' +
                                'que l’on appelle bébé bleu. Nous allions à chaque semaine au CLSC de notre région pour ' +
                                'faire prendre sa saturation ainsi que chaque 2 semaines au CHU de Québec pour des ' +
                                'échographies cardiaque.',
                            par4: 'À 9 mois, soit le 20 octobre (je m’en rappellerai toujours… lendemain de mon ' +
                                'anniversaire…) Brandon fut opéré à cœur ouvert pour une opération de l’on nomme ' +
                                'chirurgie de GLEN, qui a pour but de dévier la veine cave supérieure (sang des bras ' +
                                'et tête) pour l’envoyer passivement vers les poumons afin de décharger son ventricule ' +
                                'droit qui était trop petit pour accueillir le sang du corps en entier.  Ils ont aussi ' +
                                'procédé avec un ballonnet pour ouvrir sa valve pulmonaire. ',
                            par5: 'Brandon a donc passé 7 heures en salle d’opération, de très longues heures ' +
                                'd’attentes et d’inquiétude. Au final, l’opération s’est assez bien déroulée mis à ' +
                                'part une déchirure mineur à son aorte, qui devait être surveillée. Une semaine passa ' +
                                'sans trop de problème et Brandon pu rentrer à la maison enfin.',
                            par6: 'Nous avions des consultations en cardiologie fréquentes au début, mais nous sommes ' +
                                'rendus à des rencontres au 2 ans car Brandon va très bien.',
                            par7: 'Par contre, Brandon devra être réopéré avant l’âge adulte afin de remplacer sa valve ' +
                                'pulmonaire qui fuit puisqu’elle a été en quelque sorte défoncée afin de l’ouvrir. Il ' +
                                'y aura donc une implantation de valve porcine. ',
                            par8: 'Brandon a maintenant 13 ans, est un garçon énergique qui ADORE les camions puisque ' +
                                'nous sommes une famille de camionneurs : son grand-père (maternel) qui possédait son ' +
                                'camion jusqu’au début 2023 (car il décédé malheureusement maintenant), son papa qui ' +
                                'avait sa compagnie de transport, son beau frère et son beau-père, ainsi que moi qui ' +
                                'administre une compagnie de transport. Brandon carbure littéralement au fuel, il est ' +
                                'capable de nommer chaque marque de camion qu’il voit passé sur les routes, bien souvent ' +
                                'à qui ils appartiennent etc. Il n’est pas surprenant qu’il veuille en conduire plus tard! ',
                        },
                        kid2: {
                            par1: 'Noah est né avec un problème de cœur : « transposition des gros vaisseaux ». Il a ' +
                                'eu une opération pour l’aorte à 4 jours de vie et une autre opération à une semaine de ' +
                                'vie, soit à cœur ouvert. Par la suite, il a été presqu’un an à l’hôpital ; souvent ' +
                                'malade avec des pneumonies. Plus tard, Noah vieillit et va mieux. Il a des rendez-vous ' +
                                'à tous les 6 mois.',
                            par2: 'L’an passé, le 24 juin, il a fait une convulsion. Ça a duré presque 20 minutes. ' +
                                'Quand ça s’est arrêté, c’était comme si Noah dormait encore et il ne reconnaissait ' +
                                'plus personne. Il parlait, mais on ne le comprenait pas. Ça a pris 2 heures pour qu’on ' +
                                'puisse le comprendre. Noah avait un blocage, alors ils ont mis un « stent » et il a' +
                                ' dû subir une autre opération à cœur ouvert, à 16 ans. Il va mieux, mais on ne sait ' +
                                'pas si l’électricité du cœur est partie ou si elle pourrait revenir. On est sur la ' +
                                'liste d’attente pour qu’ils puissent retourner au cœur avec une caméra voir si ' +
                                'l’électricité va revenir ou pas.',
                        },
                        yearsOld: 'ans',
                        more: "L'histoire de ",
                        knowMore: 'Cliquez ici pour en apprendre davantage sur son histoire.',
                    },
                    starTrucks: 'Camions vedettes',
                    history: {
                        title: 'Notre histoire',
                        par1:
                            'Pour ceux et celles qui ne connaissent pas le Festival Truck \'N\'' +
                            ' Roll En Coeur, voici un bref historique.',
                        par2:
                            'C’est en 1995 que l’événement a débuté à la Marina St-Tropez à Ste-Blaise-sur-Richelieu.' +
                            ' À cette époque, il n’y avait que quelques camions exposés sur le terrain. C’est au ' +
                            'fil des années que le festival a grossi et a pris de l’ampleur.',
                        par3:
                            'Après 14 belles années à Ste-Blaise-sur-Richelieu, le Truck’n’Roll En Coeur s’est déplacé du côté de ' +
                            'Huntingdon. Le terrain était plus gros, donc nous a permis d’avoir plus de camions exposés. ' +
                            'Aussi, c’est à cet endroit que la parade de poids lourds a pris place, dans les rues ' +
                            'avoisinantes du terrain. De plus, les tirs de camions sur terre battue ont débuté lorsque ' +
                            'le Truck’n’Roll était à Huntingdon.',
                        par4:
                            'En 2013, le rassemblement de camions s’est déplacé vers l’Est de la province pour se rendre ' +
                            'à Bedford. Nous sommes présentement situés sur le terrain de la foire agricole. Depuis ' +
                            'quelques années, nous frôlons les 300 camions sur le site durant la fin de semaine.',
                        par5:
                            'Tous les profits amassés durant la fin de semaine du festival vont à la Fondation ' +
                            'En Coeur. Cette fondation vient en aide aux enfants ayant une malformation cardiaque ainsi ' +
                            'qu’à leur famille. On espère vous revoir cet été les 23-24-25 août 2024 pour la 28ieme édition!',
                        button: 'Achetez votre billet maintenant!',
                    },
                    close: 'Fermer ',
                    days: 'jours',
                    hours: 'heures',
                    minutes: 'minutes',
                    seconds: 'secondes',
                    countdown: "Le Truck N Roll a lieu le 23-24-25 août 2024 pour la 28ième édition!",
                    eventStarted: 'Le Truck N Roll a débuté! Venez nous voir!',
                },
                registration: {
                    label: 'Inscriptions',
                    truck: {
                        title: 'Inscription de camions',
                        information: 'Information',
                        info1: 'Tous les camionneurs doivent passer au kiosque d’inscription lorsqu’ils arrivent sur le terrain.' +
                            ' Un carton leur sera remis afin d’être en mesure de les juger. Tous les camions sont' +
                            ' automatiquement inscrit dans le Show n’ Shine, avec une inscription payée.',
                        info2: 'Les camionneurs ont jusqu’à samedi 14h pour s’inscrire pour le Show ‘n Shine. ',
                        info2note: '*Veuillez noter que s’ils ne passent pas au kiosque d’inscription avant cette heure ' +
                            'ils ne pourront pas être jugés.',
                        info3: '* Les pré-inscriptions commenceront sous peu.',
                        seePrices: 'Voir les tarifs d\'inscription des camions',
                    },
                    camping: {
                        title: 'Insciption camping',
                        rules: 'Règles du camping',
                        rule1: 'Chiens autorisés pourvu qu’ils aient une laisse et une muselière.',
                        rule2: 'Ramassez les excréments de votre chien.',
                        rule3: 'Pas de feu de camp.',
                        rule4: 'Gardez votre place propre - déchets à la poubelle.',
                        rule5: 'Camping sans services - eau, électricité, ni fosse septique.',
                        seePrices: 'Voir les tarifs d\'inscription des campings',
                    },
                    kiosque: {
                        title: 'Inscription kiosque',
                    },
                    sponsors: {
                        title: 'Demande de commandite'
                    },
                    online: {
                        title: 'Inscription en ligne',
                    },
                    mail: {
                        title: 'Inscription par la poste ou par courriel',
                        info: 'Téléchargez la version PDF ',
                        clickHere: 'ici',
                        sendTo: ' et envoyer le à info@festivaltrucknrollencoeur.ca ou par la poste'
                    },
                    button: 'Inscrivez-vous dès maintenant!',
                    availableSoon: 'Bientôt disponible!',
                },
                schedule: {
                    label: 'Programmation',
                    day1: {
                        title: "Vendredi 23 Août",
                        events: {
                            1: "Ouverture du site à 16h",
                            2: "Spectacle de Pic Bois à 19h",
                            3: "Spectacle de Rock this Country band à 21h",
                            4: "Party sous le chapiteau avec DJ Dayze à 23h",
                        },
                    },
                    day2: {
                        title: "Samedi 24 Août",
                        events: {
                            1: "Ouverture du site à 9h",
                            2: "Tirs de camions à 12h30",
                            3: "Souper méchoui à 17h (en quantité limité)",
                            4: "Spectacle de Nadia Waltz à 19h",
                            5: "Spectacle de The Lux à 21h30",
                            6: "Party sous le chapiteau avec DJ Grichu à 23h",
                        },
                    },
                    day3: {
                        title: "Dimanche 25 Août",
                        events: {
                            1: "Ouverture du site à 9h",
                            2: "Animation de Rosie D clown 9h à 12h",
                            3: "Concours d'habiletés de camionneurs à 11h",
                            4: "Envolée de ballons à 14h",
                            5: "Remise de trophées à 14h30",
                            6: "Par la suite, il y aura de l'animation",
                        },
                    },
                    weekend: {
                      title: 'Aussi, toute la fin de semaine',
                      events: {
                          1: 'Activités et animations pour les enfants',
                          2: 'Show n\' Shine',
                          3: 'Cantines',
                          4: 'Raffraichissements',
                          5: 'Kiosques',
                          6: 'Et plus encore!'
                      },
                    },
                    approximatif: '*Les heures sont approximatives',
                    buyYourMechouiTicketsHere: 'Achetez vos billets ici',
                    videoPromo: 'Regardez la video promo ici',
                    visitPromoHere: 'Visitez la page promo ici',
                },
                fees: {
                    label: 'Tarifs',
                    presale: {
                        title: 'Prix de prévente',
                        item1: 'La prévente se termine le lundi 5 Août',
                        item2: 'Camion Bobtail : ',
                        item3: 'Camion Combo (camion et remorque) : ',
                        item4: 'Roulotte : ',
                        item5: 'Tente : ',
                        item6: 'Vous obtenez un rabais lorsque vous achetez via notre billeterie en ligne.',
                    },
                    visitor : {
                        title: 'Passe visiteur',
                        item1: 'Entrée gratuite pour les enfants de 12 ans et moins',
                        item2: 'Passe week-end : ',
                        item3: 'Vendredi : ',
                        item4: 'Samedi : ',
                        item5: 'Dimanche : ',
                        item6: 'Billet pour le méchoui adulte : ',
                        item7: 'Billet pour le méchoui enfant',
                    },
                    regular : {
                        title: 'Prix réguliers',
                        item1: 'Les prix réguliers commencent le mardi 6 Août',
                        item2: 'Camion Bobtail : ',
                        item3: 'Camion Combo (camion et remorque) : ',
                        item4: 'Roulotte : ',
                        item5: 'Tente : ',
                    },
                    entry : ' (une entrée)',
                    entriesAndDiner: ' (2 entrées et un souper)',
                    entries: ' (2 entrées)',
                    under12: ' (12 ans et moins) : ',
                    todetermined: ' À determiné',
                    button: 'Achetez votre billet ici',
                },
                rules: {
                    label: 'Règlements',
                    title: 'Règlements du festival',
                    terrain: {
                        title: 'Sur le terrain',
                        rule1: 'Un service de sécurité est offert sur le terrain toute la fin de semaine.',
                        rule2: 'Aucune violence ni manque de respect ne sera toléré.',
                        rule2Note: "*Veuillez noter qu'une personne qui ne respectera pas cela pourrait se faire " +
                            "rencontrer par la sécurité et pourrait être demandé de quitter les lieux.",
                        rule3: 'Nous ne sommes pas responsables des dommages causés par autrui.',
                        rule4: 'Tous véhicules motorisés / électriques sont interdits sur le terrain: voiturettes de' +
                            ' golf, trotinettes électriques, etc. Seules les personnes avisées ont l\'autorisation' +
                            ': bénévoles et organisateurs.',
                        rule4Note: '*Veuillez noter qu\'une personne en situation d\'handicap ayant besoin d\'un ' +
                            'quadriporteur ou tout autre adaption est accepté.',
                        rule5: 'Les glacières avec de la nourriture et des boissons sont interdites pour les visiteurs' +
                            ', y compris les boissons alcoolisées. Des boutteilles d\'eau sont remises gratuitement ' +
                            'sur le terrain en plus des collations pour les enfants. Il y a plusieurs cantines mobiles ' +
                            'sur le terrain en plus d\'un service de bar.',
                        rule5Note: '*Veuillez noter que nous comprenons et acceptons les exceptions tels qu\'une ' +
                            'personne avec des problèmes de santé nécessitant une alimentation particulière. Il se ' +
                            'peut qu\'un agent de sécurité et/ou un bénévole fouille votre sac à l\'entrée. Il se peut ' +
                            'également qu\'il vous demande de reporter votre glacière dans votre véhicule si vous' +
                            ' ne vous soummettez pas aux règlements.',
                        rule6: 'Les chiens ne sont pas acceptés sur le terrain.',
                        rule7: 'Pensez à garder l\'endroit propre en jetant vos ordures à la poubelle.',
                        rule8: 'Pour les visiteurs et campeurs, s’il vous plaît, limitez vos déplacements vendredi le ' +
                            '23 août entre 18h et 20h. Ce sera la parade de camions et certaines rues de Bedford seront ' +
                            'barrées. L’entrée du terrain du côté de l’aréna sera aussi restreinte.',
                        rule9: 'Durant les prestations musicales, s’il vous plaît, soyez respectueux envers celles-ci ' +
                            'et le voisinage (limitez vos flûtes de camion).',
                        rule10: 'Pour la sécurité de tous, les véhicules ne sont pas permis sur le terrain durant le ' +
                            'festival (les visiteurs doivent se stationner à l’extérieur du terrain, les vendeurs ' +
                            'doivent le plus possible retirer leur véhicule dès que possible, les campeurs peuvent se ' +
                            'référer aux règlements du camping). ',
                        rule10Note: '*Veuillez noter que si un véhicule circule sur le terrain, une personne bénévole ' +
                            'ou un agent de sécurité pourrait l’avertir et lui demander de sortir le véhicule du ' +
                            'terrain. Quelques exceptions peuvent survenir.',
                        rule11: 'Des douches sont à votre disposition à l’aréna (à la sortie du terrain par l’entrée ' +
                            'des camions). Ce sont des douches communes divisées hommes et femmes. Merci à la Ville de ' +
                            'Bedford pour ce service. Nous vous demandons de respecter les lieux, les garder propres, sans ' +
                            'faire de vandalisme. ',
                        ruleShower: 'Les douches seront ouvertes:',
                        showerFriday: 'Vendredi le 23 août de 18h à 22h.',
                        showerSaturday: 'Samedi le 24 août de 8h à 22h.',
                        showerSunday: 'Dimanche le 25 août de 8h à 12h.',
                    },
                    truck: {
                        title: 'Camions',
                        rule1: 'Tous les camionneurs doivent passer au kiosque d’inscription lorsqu’ils arrivent.' +
                            ' Un carton leur sera remis afin d’être en mesure de les juger. Tous les camions sont' +
                            ' automatiquement inscrit dans le Show n’ Shine, avec une inscription payée.',
                        rule2: "Les camionneurs ont jusqu'à samedi 14h pour s'inscrire pour le Show 'n Shine.",
                        rule2Note: " *Veuillez noter que s'ils ne passent pas au kiosque d'inscription avant cette heure," +
                            " ils ne pourront pas être jugés.",
                        rule3: 'Durant la parade, nous acceptons seulement les camions bobtail (sans remorque).',
                    },
                    camping: {
                        title: 'Camping',
                        rule1: 'Notre camping est offert sans service (eau, électricité et fosse septique).',
                        rule2: 'Nous vous demandons de déposer votre roulotte, tente et autres effets personnels sur ' +
                            'votre terrain du camping, ensuite sortir votre véhicule du terrain pour faire de la place.',
                        rule3: 'Les chiens sont tolérés dans la section camping seulement avec laisse et muselière.' +
                            ' Chaque chien doit rester sur son propre terrain de camping. La personne responsable du ' +
                            'chien doit s\'assurer de ramasser ses excréments.',
                        rule4: 'Les feux de camp sont interdits.',
                        rule5: 'Veuillez garder votre place propre (déchets à la poubelle).',
                    }
                },
                sponsors: {
                    label: 'Commanditaires',
                    principal: 'Principaux',
                    diamond: 'Diamant',
                    platinum: 'Platine',
                    gold: 'Or',
                    silver: 'Argent',
                    bronze: 'Bronze',
                    registrationLink: 'Devenez un commanditaire!',
                },
                indications: {
                    label: 'Directions',
                    truckEntry: 'Entrée des camions, kiosques, campeurs et les cantines.',
                    visitorEntry: 'Entrée des visiteurs',
                    truckEntryInfo: '',
                },
                contactUs: {
                    label: 'Nous Joindre',
                    name: {
                        label: 'Nom',
                        placeholder: 'Entrez votre nom',
                    },
                    email: {
                        label: 'Adresse E-mail',
                        placeholder: 'Entrez votre adresse E-mail'
                    },
                    message: {
                        label: 'Message',
                        placeholder: 'Entrez votre message',
                    },
                    button: 'Envoyer',
                    more: '*Vous pouvez également nous contacter par e-mail à info@festivaltrucknrollencoeur.ca',
                    error: {
                        fillAllFields: "Remplissez toutes les informations s'il vous plait.",
                        invalidEmail: 'Email invalide.',
                    },
                    success: {
                        title: 'Message envoyé avec succès !',
                        text: 'Nous avons bien reçu votre message. Nous vous contacterons bientôt.',
                        btn: 'Retour à l\'accueil',
                    },
                },
                designedBy: 'Conçu par : Emmanuel Trudeau',
                language: 'English',
            }
        },
        en: {
            translation: {
                name: 'Truck \'N\' Roll En Coeur Festival',
                home: {
                    label: 'Home',
                    description: {
                        title: 'What is Truck \'N\' Roll?',
                        text: 'Festival Truck’n’Roll En Coeur is not just a truck show or a music festival. It’s a ' +
                            'great opportunity to have fun with family and friends. You can also learn more about the ' +
                            'trucking profession. All funds raised during this weekend will go to the Fondation En ' +
                            'Coeur, which helps children with heart condition and their families.',
                        link: 'Learn more about the Foundation En Coeur by visiting: ',
                    },
                    starKids: {
                        title: 'Starring Kids',
                        kid1: {
                            par1: 'I introduce to you my son Brandon Boulet, who was born with a congenital heart ' +
                                'malformation, diagnosed at 32 weeks and 6 days of pregnancy exactly. Doctors discovered ' +
                                'that the right cavity of Brandon’s heart was smaller and more underdeveloped than ' +
                                'normal and that the valve of his pulmonary artery was wedged. The diagnosis: Brandon ' +
                                'had a right heart hypoplasia and a pulmonary atresia. The prognostics were quite ' +
                                'good, but Brandon was going to seek medical attention immediately at birth. Due to ' +
                                'his malformations, the right side of his heart wouldn’t be strong enough to send the ' +
                                'unoxygenated blood to the lungs in order for it to be reoxygenated, which would mean ' +
                                'Brandon’s cells would quickly lack of oxygen.',
                            par2: 'Brandon had to undergo 2 surgeries which were performed by catheter, at only 5 and ' +
                                '6 days old. The purpose of the surgery was to place a stent in his arterial canal ' +
                                '(the canal that feeds the fetus via the umbilical cord in utero). This canal closes ' +
                                '24 to 48 hours after birth for the heart to take 100% of its function itself. ' +
                                'Unfortunately, Brandon’s small heart had to continue its in utero functions because ' +
                                'there was no passage for the blood to flow from the pulmonary artery to the lungs, ' +
                                'this is why the placement of this stent was necessary for him to survive. The operation ' +
                                'did not work on Brandon’s 5th day of life seeing as his little body was becoming ' +
                                'too blue to continue. So, they resumed the operation the next day, which was a success. ' +
                                'The stent placement was a temporary solution, giving his heart time to grow a ' +
                                'little before undergoing an open-heart intervention.',
                            par3: 'Brandon was able to return home after 2 weeks of hospitalization. His health was ' +
                                'still good except the fact that his saturation level (oxygen level in the blood) ' +
                                'wasn’t where it was supposed to be. His saturation fluctuated between 75 and 85% ' +
                                'while a healthy child should be between 95 and 100%. So, we had to watch signs for ' +
                                '‘’the blue baby‘’ syndrome. We went every week to the CLSC in our region to take ' +
                                'his saturation as well as every 2 weeks to the CHU de Québec for cardiac ultrasounds. ',
                            par4: 'At 9 months old, on October 20th (I will always remember… the day after my birthday…) ' +
                                'Brandon had to have an open heart operation called ‘’GLEN surgery‘’, which purpose is ' +
                                'to deflect the upper vena cava (blood of the arms and head) to send the blood ' +
                                'passively to the lungs therefore discharging his right ventricle , which was too small ' +
                                'to accommodate the circulation arriving from his entire body. They also installed a ' +
                                'small balloon to open his pulmonary valve.',
                            par5: 'Brandon therefore spent 7 hours in the operating room, which were very long hours of ' +
                                'waiting and worry. In the end, the operation went quite well apart from a minor tear in ' +
                                'his aorta, which had to be monitored. A week passed without too many problems and ' +
                                'Brandon was finally allowed to go home. ',
                            par6: 'We had frequent cardiology consultations at the beginning, but we now only have ' +
                                'checkups every 2 years because Brandon is doing very well.',
                            par7: 'On the other hand, Brandon will have to be reoperated before adulthood to replace ' +
                                'his leaking pulmonary valve that was unfortunately torn during the opening of his ' +
                                'artery. There will therefore be a porcine valve implantation. ',
                            par8: 'Brandon is now 13 years old, he is an energic boy who LOVES trucks because we are ' +
                                'a family of truck drivers: his (maternal) grandfather who owned a truck until the ' +
                                'beginning of 2023 (because he unfortunately passed), his father who had his transport ' +
                                'company, his brother-in-law and his father-in-law, as well as myself who manages a ' +
                                'transport company. Brandon is extremely passionate about trucks, he can name each ' +
                                'truck brand he sees passing on the roads, and even often the company they belong ' +
                                'to, etc. It’s not surprising that he wants to drive some when he gets older!',
                        },
                        kid2: {
                            par1: 'Noah was born with a heart malformation called “transposition of the great arteries”.' +
                                ' He had his first heart operation for his aorta at 4 days old, and a second ' +
                                'operation when he was a week old, which was an open-heart surgery. Then, he was ' +
                                'hospitalized for almost a full year because he was often sick with pneumonias. ' +
                                'As he got older, his condition got better, and he wasn’t sick as often. Noah had ' +
                                'regular appointments at the hospital every 6 months.',
                            par2: 'Last year, June 24th, Noah had a seizure that lasted almost 20 minutes. When it ' +
                                'stopped, it was like he was still sleeping, and he couldn’t recognize anybody. He was ' +
                                'talking, but we couldn’t understand what he was trying to tell us. This scary moment ' +
                                'lasted almost 2 hours, before he started coming back to his normal state of mind. We ' +
                                'soon found out, that Noah had a blockage in an artery, which meant they had to install' +
                                ' a stent. For this procedure, he underwent another open-heart surgery, at 16 years old. ' +
                                'He’s doing good now, but we don’t know if the electricity in the heart is gone or if it ' +
                                'will come back. We are on the waiting list for a procedure, that will send a camera near ' +
                                'Noah’s heart to check on its electricity.',
                        },
                        yearsOld: 'years old',
                        more: 'The journey of ',
                        knowMore: 'Click here to learn about his journey.'
                    },
                    starTrucks: 'Starring Trucks',
                    history: {
                        title: 'Our history',
                        par1:
                            'For those who don’t know the Truck\'n\'Roll En Coeur Festival, here’s a brief history.',
                        par2:
                            'In 1995, the event started at the marina St-Tropez in Ste-Blaise-sur-Richelieu. Back then, ' +
                            'there were just a few trucks exposed. Over the years Truck’n’Roll became a bigger festival.',
                        par3:
                            'After 14 wonderful years in Ste-Blaise, Truck’n’Roll En Coeur headed to Huntingdon, Qc. ' +
                            'The site was bigger, so it was perfect for gathering more trucks, which made it easier to ' +
                            'have a parade in the streets of the town. In Huntington, we had truck pulls, which we still ' +
                            'have today in Bedford.',
                        par4:
                            'In 2013, Truck’n’Roll En Coeur moved to the East of the province and settled down in ' +
                            'Bedford, Qc. We are now located on the fair grounds of the town. The last few years, there' +
                            ' was almost 300 trucks that were on the site all weekend long.',
                        par5:
                            'All profits from the festival are given to the Fondation En Coeur. This foundation' +
                            ' helps children with heart malformations and their families. We hope to see you again' +
                            ' this year, August 23-24-25th 2024 for the 28th edition!',
                        button: 'Buy your ticket now!',
                    },
                    close: 'Close',
                    days: 'days',
                    hours: 'hours',
                    minutes: 'minutes',
                    seconds: 'seconds',
                    countdown: "Truck N Roll takes place on August 23-24-25, 2024 for the 28th edition!",
                    eventStarted: 'Truck N Roll has started! Come visit us!',
                },
                registration: {
                    label: 'Registrations',
                    truck: {
                        title: 'Truck registration',
                        information: 'Information',
                        info1: 'All truckers must pass at the registration booth when they arrive. A classification ' +
                            'paper will be given to the truckers in order for them to be judged. All trucks are ' +
                            'automatically registered in the Show n’ Shine contest as they pay their entry fee.',
                        info2: 'Truckers have until 2 p.m. Saturday to register for Show ‘n Shine.',
                        info2note: ' *Please note that if they do not come to the registration booth before this time, ' +
                            'they cannot be judged.',
                        info3: '* Pre-registrations will begin shortly.',
                        seePrices: 'See the prices for the truck registration.',
                    },
                    kiosque: {
                        title: 'Booth registration',
                    },
                    sponsors: {
                        title: 'Sponsorship Request'
                    },
                    camping: {
                        title: 'Camper sign up',
                        rules: 'Camping rules',
                        rule1: 'Dogs are accepted in the camping area only, with leashes and muzzles. Every dog must ' +
                            'stay on their own campsite. The dog owner must ensure to pick up its excrements.',
                        rule2: 'No campfire allowed.',
                        rule3: 'We ask you to drop off your fifth-wheel, tent, and other personal belongings on your ' +
                            'campsite. Then take your vehicle out of the site to make space.',
                        rule4: 'Please keep your place clean (waste in the garbage).',
                        rule5: 'The camping area has no services (water, electricity, and septic system).',
                        seePrices: 'See the prices for the camping registration.',
                    },
                    online: {
                        title: 'Online registration',
                        info: 'Register'
                    },
                    mail: {
                        title: 'Mail or Email registration',
                        info: 'Download the PDF version ',
                        clickHere: 'here',
                        sendTo: ' and send it to info@festivaltrucknrollencoeur.ca or by mail'
                    },
                    button: 'Sign up now!',
                    availableSoon: 'Available soon!',
                },
                schedule: {
                    label: 'Weekend schedule',
                    day1: {
                        title: "Friday August 23rd",
                        events: {
                            1: "Site opens at 4 PM",
                            2: "Pic Bois show at 7 PM",
                            3: "Rock this Country band show at 9 PM",
                            4: "Party under the tent with DJ Dayze at 11 PM",
                        },
                    },
                    day2: {
                        title: "Saturday August 24th",
                        events: {
                            1: "Site opens at 9 AM",
                            2: "Truck pulls at 12h30 PM",
                            3: "Pig roast supper at 5 PM (in limited quantity)",
                            4: "Nadia Waltz show at 7 PM",
                            5: "The Lux show at 9h30 PM",
                            6: "Party under the tent with DJ Grichu at 11 PM",
                        },
                    },
                    day3: {
                        title: "Sunday August 25th",
                        events: {
                            1: "Site opens at 9 AM",
                            2: "Entertainment Rosie D clown from 9 AM to 12 PM",
                            3: "Truckers ability contest at 11 AM",
                            4: "Balloon ceremony at 2 PM",
                            5: "Awards ceremony at 2h30 PM",
                            6: "There will be animation after",
                        },
                    },
                    weekend: {
                        title: 'All weekend long',
                        events: {
                            1: 'Children\'s entertainment and activities',
                            2: 'Show n\' Shine',
                            3: 'Canteens',
                            4: 'Refreshments',
                            5: 'Vendors',
                            6: 'And more!',
                        },
                    },
                    approximatif: '*Please note that the hours specified are approximate.',
                    buyYourMechouiTicketsHere: 'Buy your tickets here',
                    videoPromo: 'Watch the promo video here',
                    visitPromoHere: 'Visit the promo website here',
                },
                fees: {
                    label: 'Rates',
                    presale: {
                        title: 'Presale Prices',
                        item1: 'Presale ends Monday, August 5th',
                        item2: 'Bobtail Truck : ',
                        item3: 'Combo Truck (truck and trailer) : ',
                        item4: 'Camper : ',
                        item5: 'Tent : ',
                        item6: 'Get a discount when you buy your tickets online.',
                    },
                    visitor: {
                        title: 'Visitor Pass',
                        item1: 'Free entry for children 12 and under',
                        item2: 'Weekend Pass : ',
                        item3: 'Friday : ',
                        item4: 'Saturday : ',
                        item5: 'Sunday : ',
                        item6: 'Adult pig roast supper ticket : ',
                        item7: 'Child pig roast supper ticket',
                    },
                    regular: {
                        title: 'Regular Prices',
                        item1: 'Regular prices start Tuesday, August 6th',
                        item2: 'Bobtail Truck : ',
                        item3: 'Combo Truck (truck and trailer) : ',
                        item4: 'Camper : ',
                        item5: 'Tent : ',
                    },
                    entry: ' (one entry)',
                    entriesAndDiner: ' (2 entries and one supper)',
                    entries: ' (2 entries)',
                    under12: ' (12 and under): ',
                    todetermined: ' To be determined',
                    button: 'Buy your ticket here',
                },
                rules: {
                    label: 'Rules',
                    title: 'Rules of the festival',
                    terrain: {
                        title: 'On the site:',
                        rule1: 'A security crew will be patrolling all weekend long.',
                        rule2: 'No violence or lack of respect will be tolerated.',
                        rule2Note: '*Please note that if someone doesn’t respect our rules, they will be asked to meet ' +
                            'the security and/or a Truck’n’Roll organizer' +
                            ' and may be asked to leave the site.',
                        rule3: 'We are not responsible for any injuries caused by others.',
                        rule4: 'All electric and motorized vehicles are prohibited on the site: cars, golf karts, ' +
                            'electric scooters, etc. Only members of the Truck’n’Roll community have permission: ' +
                            'volunteers and organizers.',
                        rule4Note: '*Please note that a person with a disability who needs a mobility scooter, or any other adaptation is accepted.',
                        rule5: 'Coolers with food and drinks are prohibited for visitors, including alcoholic beverages. ' +
                            'Bottles of water are given on the site along with free snacks for the kids. There are food ' +
                            'trucks on the site as well as a bar service. ',
                        rule5Note: '*Please note that we understand and accept exceptions such as: a person with a ' +
                            'health condition that requires a strict diet. It may be possible that a security guard ' +
                            'and/or a volunteer asks to check what’s in your bag or cooler. Also, they can ask you to ' +
                            'return your cooler to your vehicle if you do not respect the rules. ',
                        rule6: 'Dogs are not allowed on the site.',
                        rule7: 'Please keep the propriety clean by putting waste in the garbage.',
                        rule8: 'For all visitors and campers, please limit your movements Friday August 23rd between ' +
                            '6pm and 8pm. During this time, we will be having our usual truck parade, therefore certain ' +
                            'roads in Bedford will be closed. The back entrance near the arena will also be obstructed.',
                        rule9: 'During the musical shows we ask that you please be respectful towards the groups and ' +
                            'the neighborhood. (Please limit trucks horns.)',
                        rule10: 'For everyone’s security, vehicles will not be allowed on site during the festival ' +
                            '(visitors will be asked to park on the outside of the site, everyone with booths will be ' +
                            'asked to remove their vehicle from the site as soon as possible, as for campers you may ' +
                            'follow instructions from the camping rule section).',
                        rule10Note: '*Please note, if a car is on the site, a volunteer or a security guard may ask you ' +
                            'to move your car outside of the site. A few exceptions will be considered.',
                        rule11: 'Showers will be available at the arena (near the back entrance). They are public showers' +
                            ' divided for men and women. Thank you to the town of Bedford for this service. We ask you ' +
                            'to respect this area, keep it clean and no vandalizing.',
                        ruleShower: 'The showers will be open on:',
                        showerFriday: 'Friday, August 23rd from 6pm to 10pm.',
                        showerSaturday: 'Saturday, August 24th from 8am to 10pm.',
                        showerSunday: 'Sunday, August 25th from 8am to 12pm.',
                    },
                    truck: {
                        title: 'Trucks:',
                        rule1: 'All truckers must pass at the registration booth when they arrive on the site. A classification ' +
                            'paper will be given to the truckers in order for them to be judged. All trucks are ' +
                            'automatically registered in the Show n’ Shine contest as they pay their entry fee.',
                        rule2: "Truckers have until Saturday 2 p.m. to register for the Show 'n Shine.",
                        rule2Note: "*Please note that if they do not stop by the registration booth before this time, " +
                            "they cannot be judged.",
                        rule3: 'During the parade, we accept bobtail trucks only (with no trailer).',
                    },
                    camping: {
                        title: 'Camping:',
                        rule1: 'The camping area has no services (water, electricity, and septic system).',
                        rule2: 'We ask you to drop off your fifth-wheel, tent, and other personal belongings on your ' +
                            'campsite. Then take your vehicle out of the site to make space.',
                        rule3: 'Dogs are accepted in the camping area only, with leashes and muzzles. Every dog must ' +
                            'stay on their own campsite. The dog owner must ensure to pick up its excrements.',
                        rule4: 'No campfire allowed.',
                        rule5: 'Please keep your place clean (waste in the garbage).'
                    }
                },
                sponsors: {
                    label: 'Sponsors',
                    principal: 'Main',
                    diamond: 'Diamond',
                    platinum: 'Platinum',
                    gold: 'Gold',
                    silver: 'Silver',
                    bronze: 'Bronze',
                    registrationLink: 'Become a sponsor!',
                },
                indications: {
                    label: 'Directions',
                    truckEntry: 'Trucks, booth, campers and canteens entry',
                    visitorEntry: 'Visitor entry',
                },
                contactUs: {
                    label: 'Contact us',
                    name: {
                        label: 'Name',
                        placeholder: 'Enter your name',
                    },
                    email: {
                        label: 'Email address',
                        placeholder: 'Enter your email'
                    },
                    message: {
                        label: 'Message',
                        placeholder: 'Enter your message',
                    },
                    button: 'Send',
                    more: '*You can also contact us by email at info@festivaltrucknrollencoeur.ca.',
                    error: {
                        fillAllFields: 'Fill in all the information please.',
                        invalidEmail: 'Invalid email',
                    },
                    success: {
                        title: 'Message sent successfully !',
                        text: 'We have received your message. We will contact you soon.',
                        btn: 'Back to Home',
                    },
                },
                designedBy: 'Designed by  : Emmanuel Trudeau',
                language: 'Français',
            }
        },
    },
}).then(() => console.log("Language loaded"));

