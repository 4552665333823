import React, { useCallback, useEffect, useState } from "react";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import "react-circular-progressbar/dist/styles.css";

function Countdown2({ eventDate }) {
    const { t } = useTranslation();
    const calculateTimeLeft = useCallback(() => {
        const difference = +new Date(eventDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    }, [eventDate]);

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [calculateTimeLeft]);

    const percentage = (value, max) => (value / max) * 100;

    if (+new Date() > +new Date(eventDate)) {
        return <h1 className="py-3 fw-bolder text-center text-danger">{t("home.eventStarted")}</h1>;
    }

    return (
        <div>
            <h2 className="pt-3 fw-bolder text-center">{t("home.countdown")}</h2>
            <Container className="d-flex flex-column align-items-center py-5">
                <div className="countdown-container">
                    <div className="progress-bar shadow-red">
                        <CircularProgressbarWithChildren
                            value={percentage(timeLeft.days, 365)}
                            styles={buildStyles({
                                pathColor: '#ff0000',
                                trailColor: '#8c9a9c',
                                pathTransitionDuration: 5,
                                strokeLinecap: 'round',
                            })}
                        >
                            <div className="value-text">{timeLeft.days}</div>
                            <div className="label-text">{t("home.days")}</div>
                        </CircularProgressbarWithChildren>
                    </div>
                    <div className="progress-bar shadow-blue">
                        <CircularProgressbarWithChildren
                            value={percentage(timeLeft.hours, 24)}
                            styles={buildStyles({
                                pathColor: '#09dceb',
                                trailColor: '#8c9a9c',
                                pathTransitionDuration: 2,
                                strokeLinecap: 'round',
                            })}
                        >
                            <div className="value-text">{timeLeft.hours}</div>
                            <div className="label-text">{t("home.hours")}</div>
                        </CircularProgressbarWithChildren>
                    </div>
                    <div className="progress-bar shadow-yellow">
                        <CircularProgressbarWithChildren
                            value={percentage(timeLeft.minutes, 60)}
                            styles={buildStyles({
                                pathColor: '#ecf00a',
                                trailColor: '#8c9a9c',
                                pathTransitionDuration: 1,
                                strokeLinecap: 'round',
                            })}
                        >
                            <div className="value-text">{timeLeft.minutes}</div>
                            <div className="label-text">{t("home.minutes")}</div>
                        </CircularProgressbarWithChildren>
                    </div>
                    <div className="progress-bar shadow-green">
                        <CircularProgressbarWithChildren
                            value={percentage(timeLeft.seconds, 60)}
                            styles={buildStyles({
                                pathColor: '#0be60b',
                                trailColor: '#8c9a9c',
                                pathTransitionDuration: 0.5,
                                strokeLinecap: 'round',
                            })}
                        >
                            <div className="value-text">{timeLeft.seconds}</div>
                            <div className="label-text">{t("home.seconds")}</div>
                        </CircularProgressbarWithChildren>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Countdown2;
